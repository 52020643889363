import { Box, Button, Typography } from '@mui/material';

type TermsOfServiceRetailerProps = {
  supplierGender: string;
  supplierName: string;
  onFinishReading?: () => void;
};

export default function TermsOfServiceRetailer({
  supplierGender,
  supplierName,
  onFinishReading,
}: TermsOfServiceRetailerProps) {
  const refArticle = supplierGender === 'a' ? 'à' : 'ao';
  const capitalizedArticle = supplierGender.toUpperCase();

  return (
    <Box p={2} display="flex" flexDirection="column" gap={2}>
      <Typography variant="h4">Termos de Uso</Typography>
      <Typography variant="body1">
        1. O que são Transações, Recebíveis, Agenda de Recebíveis e Contratos?
      </Typography>
      <Typography variant="body1">
        “Transações” são todas e quaisquer operações relativas a aquisições de
        bens e/ou contratação de serviços realizadas por portadores de cartão de
        crédito, online em websites, aplicativos, por link de pagamentos ou no
        mundo físico nas chamadas maquininhas. As Transações originam os
        “Recebíveis” e o conjunto de Recebíveis é referido como “Agenda de
        Recebíveis”. Os Recebíveis podem ser usados por você como garantia ou
        até para realizar pagamentos a seus fornecedores e credores, essa
        negociação é formalizada por um {'"Contrato"'}.
      </Typography>
      <Typography variant="body1">
        2. O que é Opt-in no âmbito do registro de recebíveis?
      </Typography>
      <Typography variant="body1">
        Ao concordar com estes Termos de Uso, você está expressamente
        concordando com o “Opt-in” que é o nome utilizado no mercado para se
        referir ao consentimento que, de forma informada e por livre e
        espontânea vontade, você concede à Pague Recebível para te representar
        perante: (A) qualquer uma das registradoras de Recebíveis, seja (i) a
        Câmara Interbancária de Pagamentos – CIP S.A., (ii) a TAG Tecnologia
        para o Sistema Financeiro S.A., (iii) CERC – Central de Recebíveis S.A.
        ou (iv) B3 S.A.- Brasil, Bolsa, Balcão (“Registradoras”), com relação a
        (B) qualquer um dos instituidores de arranjo de pagamento tais como (i)
        Visa, (ii) Mastercard, (iii) Hiper, (iv) American Express, (v) Elo, bem
        como quaisquer outras bandeiras de cartão (“Bandeiras”), cujas
        Transações foram processadas por (C) qualquer credenciador ou
        subcredenciador como (i) Stone, (ii) PagSeguro, (iii) Cielo, (iv) Rede,
        (v) GetNet, (vi) Global, (vii) SafraPay, (viii) FirstData, incluindo
        quaisquer outros credenciadores ou subcredenciadores (“Credenciadores”);
        todos existentes ou que venham a existir. Ainda, referido consentimento,
        com relação a quaisquer Registradoras, Bandeiras, Credenciadores, seus
        fornecedores e eventuais credores, permite que {supplierGender}{' '}
        {supplierName} (tendo a Pague Recebível como intermediadora): (i)
        consulte sua Agenda de Recebíveis; (ii) compartilhe informações
        cadastrais e financeiras referentes a você, sua empresa e suas
        Transações com as Registradoras e eventuais credores e fornecedores;
        (iii) realize contestações; (iv) pratique todos os atos necessários para
        o fiel cumprimento de suas instruções e correta prestação de serviços.
        Com seu {'"de acordo"'}, com relação às Operações sobre Recebíveis
        (termo abaixo definido), que poderá ser formalizado por meio de
        WhatsApp, e-mail, SMS, opt-in online, bem como qualquer forma
        juridicamente aceita, {supplierGender} {supplierName} (tendo a Pague
        Recebível como intermediadora) poderá: (i) realizar registro de
        Contratos; (ii) alterar titularidade de Contratos e Recebíveis;(iii)
        informar instituição de domicílio bancário; (iv) ceder seus Recebíveis a
        seus fornecedores e eventuais credores; sempre seguindo suas instruções
        e com seu consentimento. Em cumprimento da legislação em vigor, por meio
        da aceitação da Política de Privacidade e dos Termos de Uso da Pague
        Recebível, esta declaração deve ser considerada para todos os fins de
        fato e de direito como Opt-in.
      </Typography>
      <Typography variant="body1">3. O que a Pague Recebível faz?</Typography>
      <Typography variant="body1">
        A Pague Recebível atua como infraestrutura no mercado financeiro,
        inicialmente focada no registro de Recebíveis, definido nas Resoluções
        CMN nº 4.734/19 e 5.045/22 e Resoluções BCB nº 264/22 e 267/22, conforme
        alteradas (“Sistema de Registro”), com a finalidade de registro de
        Contratos de negociação de Recebíveis, alteração de titularidade de
        Contratos e consulta da Agenda de Recebíveis (“Operação sobre
        Recebíveis”).
      </Typography>
      <Typography variant="body1">
        4. Quais são os serviços prestados pela Pague Recebível?
      </Typography>
      <Typography variant="body1">
        A Pague Recebível presta serviços de intermediação para formalização da
        cessão de Recebíveis que você tem direito a receber em decorrência de
        Transações. Referidos Recebíveis, por sua vez, podem ser cedidos por
        você para seus fornecedores como forma de pagamento a estes, mediante
        alteração da titularidade nas Registradoras, ou seja, você não precisa
        aguardar o prazo de pagamento dos valores referentes às Transações já
        realizadas, pode acessá-las no dia seguinte de sua realização para
        adquirir produtos ou contratar serviços. Ainda, a Pague Recebível possui
        o serviço de pagamento pré-aprovado nos termos acordados entre você e
        seus fornecedores cadastrados na Pague Recebível. Referidos pagamentos
        podem ser de valores fixos ou variáveis, com ou sem limite, de forma
        recorrente ou não, por um período pré-determinado ou não, sempre em
        estrito cumprimento às instruções concedidas por seus fornecedores
        acerca das negociações que possui com você. É importante deixar claro
        que {supplierGender} {supplierName} (tendo a Pague Recebível como
        intermediadora) poderá utilizar seus Recebíveis como forma de pagamento
        de valores a qualquer tempo devidos por você aos seus fornecedores que
        sejam contratantes dos serviços da Pague Recebível, mesmo se tais
        débitos forem referentes às negociações anteriores à contratação da
        Pague Recebível. {capitalizedArticle} {supplierName} (tendo a Pague
        Recebível como intermediadora) também pode acessar sua Agenda de
        Recebíveis e compartilhar informações com seus fornecedores ou credores.
        Por fim, {supplierGender} {supplierName} (tendo a Pague Recebível como
        intermediadora) poderá fazer análises com base nos dados de Transações.
        A Operação sobre Recebíveis é realizada em decorrência de permuta,
        doação em pagamento, desconto de Recebíveis, dentre outras hipóteses de
        negociação com seu fornecedor ou credor, sobre a qual a Pague Recebível
        não possui nenhuma ingerência ou responsabilidade. Você deverá resolver
        diretamente com seu fornecedor ou credor toda e qualquer controvérsia.
        Em caso de insuficiência ou inexistência por qualquer razão dos
        Recebíveis negociados com seu fornecedor ou credor, {supplierGender}{' '}
        {supplierName} (tendo a Pague Recebível como intermediadora) poderá
        efetuar novas Operações sobre Recebíveis, limitadas ao valor pactuado
        até o adimplemento da obrigação. Se não houver êxito, o credor poderá
        acioná-lo extrajudicial e/ou judicialmente, bem como se utilizar de
        quaisquer formas de cobrança permitidas pela legislação.
      </Typography>
      <Typography variant="body1">5. Quais são as suas obrigações?</Typography>
      <Typography variant="body1">
        - Observar o cumprimento de todas as regras impostas pelo Banco Central
        do Brasil, Credenciadores e Bandeiras, tais como, mas não limitadas,
        àquelas que tratam de riscos e compliance.
      </Typography>
      <Typography variant="body1">
        - Prestar à Pague Recebível toda e qualquer informação necessária para a
        prestação dos serviços, bem como disponibilizar quaisquer documentos
        solicitados pela Pague Recebível para comprovação das informações.
      </Typography>
      <Typography variant="body1">
        - Manter seus dados cadastrais atualizados e informar à Pague Recebível
        qualquer alteração dos dados no prazo de até 5 (cinco) dias contados da
        alteração. Caso existam valores abertos para com a Pague Recebível, seus
        fornecedores e/ou credores, a título de remuneração por aquisição de
        produtos, contratação de serviços ou indenizações, conforme o caso, você
        autoriza a Pague Recebível a realizar o registro no Sistema de
        Recebíveis, constituídos ou a constituir.
      </Typography>
      <Typography variant="body1">
        6. Quais são as responsabilidades e limitações de responsabilidades da
        Pague Recebível?
      </Typography>
      <Typography variant="body1">
        A Pague Recebível é uma intermediadora, prestadora de serviços que
        cumpre estritamente as instruções dadas por você, seu fornecedor e/ou
        credor, sem qualquer gestão sobre as partes da negociação ou sobre os
        recursos. Assim sendo, a Pague Recebível não responde pela liquidação de
        Transações.
      </Typography>
      <Typography variant="body1">
        A Operação sobre Recebíveis é uma forma de registro eletrônico que visa
        a produção de efeitos contra terceiros e não garante que o valor
        registrado será efetivamente liquidado, total ou parcialmente. Os
        Recebíveis, ainda que constituídos e registrados, podem sofrer redução
        de valor em consequência de fatores externos, como cancelamentos,
        chargeback, dentre outros, sendo a Pague Recebível isenta de qualquer
        responsabilidade por perdas decorrentes de tal redução. O Sistema de
        Registro é composto por diversas Registradoras conectadas em ambiente de
        interoperabilidade, eventuais falhas das Registradoras e/ou do ambiente
        de interoperabilidade não podem ser atribuídos à Pague Recebível. Os
        serviços prestados pela Pague Recebível estão em constante melhoria,
        assim demoras na execução podem ocorrer em alguns momentos. Há ainda,
        possibilidade de interrupção nos serviços por razões técnicas, em razão
        de manutenção preventiva ou corretiva ou, ainda, por motivos de caso
        fortuito ou força maior. A Pague Recebível, portanto, exime-se de
        responsabilidade por eventuais Operações sobre Recebíveis que deixem de
        ser realizadas durante os períodos de indisponibilidade. A Pague
        Recebível adota medidas de segurança adequadas de acordo com os padrões
        de mercado para a proteção de Dados Pessoais. Assim sendo, a Pague
        Recebível não se responsabilizará por qualquer exclusão, obtenção,
        utilização ou divulgação não autorizada de Dados Pessoais resultantes de
        ataques cibernéticos que a Pague Recebível razoavelmente não poderia
        evitar.
      </Typography>
      <Typography variant="body1">
        7. Como ocorre o encerramento do relacionamento entre a Pague Recebível
        e você?
      </Typography>
      <Typography variant="body1">
        As partes poderão encerrar o relacionamento a qualquer momento mediante
        simples descredenciamento e informação por meio dos canais de
        comunicação. São razões que podem levar a Pague Recebível a optar pelo
        término do relacionamento com você: (i) descumprimento por você da
        Política de Privacidade e/ou Termos de Uso; (ii) decretação de falência,
        pedido de recuperação judicial ou extrajudicial ou declaração de
        insolvência; (iii) atraso no pagamento de quaisquer taxas, tarifas,
        custos e reembolsos devidos pelo seu fornecedor à Pague Recebível por
        mais de 30 (trinta) dias corridos; (iv) hipótese de inviabilidade de
        execução do modelo de negócios por alteração das condições regulatórias
        ou contratuais; e/ou (v) solicitação das Registradoras, Banco Central do
        Brasil ou qualquer autoridade competente. Vale mencionar que mesmo após
        o término do relacionamento as Operações sobre Recebíveis já executadas
        permanecerão válidas por força de regulamentação até o fim da vigência
        da Operação sobre Recebíveis. Além das hipóteses previstas acima, a
        Pague Recebível pode se recusar, a qualquer momento e independentemente
        de notificação, a realizar Operações sobre Recebíveis, bem como realizar
        seu desligamento, bloqueio ou suspensão, sempre que suspeitar de sua
        legalidade, lisura, consentimento válido ou capacidade de adimplemento,
        bem como por probabilidade de redução do montante, questões
        operacionais, de risco e/ou compliance, dentre outras.
      </Typography>
      <Typography variant="body1">
        Disposições Gerais Aplicáveis à Política de Privacidade e aos Termos de
        Uso
      </Typography>
      <Typography variant="body1">1. Comunicações</Typography>
      <Typography variant="body1">
        Ao contratar ou utilizar os serviços da Pague Recebível você nos
        autoriza a entrar em contato com você por meio de e-mails, mensagens,
        SMS, pushes, WhatsApp, ou telefone para que você acompanhe de forma
        segura os serviços contratados. Após a leitura da Política de
        Privacidade e Termo de Uso, se você tiver dúvidas, reclamações ou quiser
        exercer seus direitos, incluindo, mas não se limitando a solicitações de
        exclusão de dados você pode entrar em contato com a Pague Recebível pelo
        e-mail privacidade@paguerecebível.com.br.
      </Typography>
      <Typography variant="body1">2. Modificações</Typography>
      <Typography variant="body1">
        A Política de Privacidade e os Termos de Uso poderão ser alterados para
        refletir modificações nos serviços ou negócios da Pague Recebível ou na
        legislação aplicável. Referidas alterações serão disponibilizadas no
        website da Pague Recebível. Caso sejam feitas alterações relevantes,
        você será ativamente informado na forma da legislação aplicável. Caso
        qualquer disposição dos Termos de Uso ou da Política de Privacidade seja
        considerada ilegal, nula ou inexequível por qualquer razão, as
        disposições restantes não serão afetadas e manter-se-ão válidas e
        aplicáveis na máxima extensão possível. A Pague Recebível se reserva ao
        direito, a seu exclusivo critério, de suspender, modificar ou encerrar
        as atividades, a qualquer tempo. A Pague Recebível poderá alterar as
        condições de prestação dos serviços ora pactuados para atender
        exigências legais e/ou regulatórias, incluindo, mas não se limitando, do
        Banco Central do Brasil, de Bandeiras e Credenciadores. Qualquer falha
        da Pague Recebível em exercer qualquer disposição dos Termos de Uso,
        e/ou da Política de Privacidade ou direitos conexos, não constitui
        novação ou renúncia a qualquer direito ou disposição.
      </Typography>
      <Typography variant="body1">3. Cessão dos Termos de Uso</Typography>
      <Typography variant="body1">
        Você não poderá ceder ou transferir a terceiros, total ou parcialmente,
        qualquer de suas obrigações ou direitos oriundos destes Termos de Uso
        sem a prévia anuência da Pague Recebível. A Pague Recebível poderá ceder
        estes Termos de Uso para empresas do mesmo grupo econômico.
      </Typography>
      <Typography variant="body1">4. Cessão de Direitos</Typography>
      <Typography variant="body1">
        Os direitos de propriedade intelectual e autorais são da Pague Recebível
        e não há outorga de qualquer direito, licença ou cessão pela Pague
        Recebível para você.
      </Typography>
      <Typography variant="body1">5. Anticorrupção</Typography>
      <Typography variant="body1">
        A Pague Recebível efetuará registros e declarações que afetam recursos e
        expectativas de terceiros e, portanto, trata com extrema seriedade todos
        os assuntos referentes às práticas anticorrupção. Neste sentido, você
        concorda e declara que não irá solicitar o registro de Operações sobre
        Recebíveis baseadas em acordos falsos, fraudulentos ou simulados e que
        irá cumprir com todas as leis e regulamentos aplicáveis, incluindo, mas
        não se limitando, à Lei Anticorrupção Brasileira, conforme alterada (Lei
        Federal n. 12.846/2013). Caso você venha a descobrir ou suspeite de
        qualquer descumprimento, por qualquer pessoa, dos Termos de Uso ou
        Política de Privacidade, deverá: (i) tomar as medidas de remediação
        adequadas; (ii) notificar a Pague Recebível; e (iii) disponibilizar à
        Pague Recebível todas as informações relevantes, incluindo, mas não se
        limitando ao acesso a qualquer pessoa ou documento que você tenha
        acesso. Você concorda em manter registros que refletem de forma apurada
        seus bens e transações e a Pague Recebível poderá ter acesso razoável e
        ágil aos registros e contratos solicitados. Se você descumprir quaisquer
        das declarações, garantias ou compromissos acima ou se a Pague Recebível
        tiver conhecimento de informação que a leve a um entendimento razoável
        de que você possa ter violado ou possa ter agido de maneira que pudesse
        levar a Pague Recebível a violar qualquer legislação, a Pague Recebível
        poderá cancelar as Operações sobre Recebíveis informadas ao Sistema de
        Registro, descredenciá-lo e encerrar o relacionamento com você
        imediatamente.
      </Typography>
      <Typography variant="body1">6. Indenizações</Typography>
      <Typography variant="body1">
        Você é responsável pelas ações ou omissões cometidas por seus
        representantes, empregados, prepostos, contratados, terceiros,
        subcontratados ou quaisquer outras pessoas físicas ou jurídicas ligadas
        a você e indenizará e eximirá a Pague Recebível contra todos e quaisquer
        danos, custos, despesas, baixas contábeis, lucros cessantes e perdas de
        qualquer natureza que forem incorridas pela Pague Recebível em
        decorrência do descumprimento de quaisquer disposições da Política de
        Privacidade, dos Termos de Uso ou da legislação. Você deverá requerer
        formalmente, perante qualquer autoridade competente, judicial e/ou
        administrativa, a substituição e/ou exclusão da Pague Recebível do polo
        passivo de demandas que a Pague Recebível não tiver dado causa. Ainda,
        você ressarcirá todos e quaisquer custos e despesas incorridos pela
        Pague Recebível para a defesa de seus interesses, incluindo os valores
        razoáveis de honorários advocatícios, de peritos, contadores e qualquer
        outra despesa decorrente da investigação, defesa, manutenção,
        preservação ou execução de quaisquer dos direitos aqui previstos, bem
        como disponibilizar informações, provas e/ou testemunhas para a
        respectiva defesa. A responsabilidade da Pague Recebível, assim como de
        suas controladoras, controladas, coligadas ou quaisquer sociedades a ela
        ligadas, para com você, estará, em qualquer hipótese, restrita aos danos
        diretos e limitada ao valor total efetivamente comprovado do dano. Os
        danos indiretos, lucros cessantes, danos morais ou qualquer outra
        modalidade de tal natureza não serão indenizados pela Pague Recebível.
        Os Termo de Uso e Política de Privacidade aplicam-se aos Dados Pessoais
        quando da utilização dos serviços da Pague Recebível e não se aplicam
        aos sites, aplicativos ou serviços de terceiros, sobre os quais a Pague
        Recebível não possui qualquer controle ou ingerência. O uso dos serviços
        em contradição com qualquer legislação, com estes Termos de Uso e
        Política de Privacidade serão de única, integral e exclusiva
        responsabilidade da parte que os utilizou de forma indevida.
      </Typography>
      <Typography variant="body1">7. Legislação e Foro</Typography>
      <Typography variant="body1">
        Os Termos de Uso e Política de Privacidade estão sujeitos à legislação
        brasileira e qualquer disputa não solucionada de forma amigável deverá
        ser submetida ao foro da comarca de Itupeva - SP, com exclusão de
        qualquer outro por mais privilegiado que seja.
      </Typography>
      {onFinishReading && (
        <Button variant="contained" onClick={onFinishReading}>
          Já li o documento, fechar
        </Button>
      )}
    </Box>
  );
}
