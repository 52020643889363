import { Box } from '@mui/material';
import TermsOfServiceRetailer from '../features/onboarding/TermsOfServiceRetailer';
import TermsOfServiceSupplier from '../features/onboarding/TermsOfServiceSupplier';
import { useAppSelector } from '../helpers/hooks';

function Termos() {
  const company = useAppSelector((state) => state.app.company);
  const supplier = useAppSelector((state) => state.app.supplier);

  if (!company) return <></>;
  if (!supplier) return <></>;

  return (
    <Box overflow="auto">
      {company.companyType === 'retailer' ? (
        <TermsOfServiceRetailer
          supplierGender={supplier.gender}
          supplierName={supplier.name}
        />
      ) : (
        <TermsOfServiceSupplier />
      )}
    </Box>
  );
}

export default Termos;
