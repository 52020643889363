import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Alert,
  Box,
  Button,
  List,
  ListItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { CPFField } from '../../components/CPFField/CPFField';
import { CheckBoxField } from '../../components/CheckBoxField/CheckBoxField';
import FileUploadWithControl from '../../components/FileUpload/FileUploadWithControl';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { PhoneField } from '../../components/PhoneField/PhoneField';
import ResponsiveDialog from '../../components/ResponsiveDialog/ResponsiveDialog';
import { format } from '../../helpers/format';
import { useAppDispatch, useAppSelector } from '../../helpers/hooks';
import {
  MimeType,
  validateFileSize,
  validateFileType,
} from '../../helpers/validations';
import useCompanyStatus from '../../hooks/useCompanyStatus';
import useUserRole from '../../hooks/useUserRole';
import { OnboardingForm } from '../../types';
import PrivacyPolicyRetailer from './PrivacyPolicyRetailer';
import TermsOfServiceRetailer from './TermsOfServiceRetailer';
import { onboardingActions } from './onboarding-slice';

function OnboardingRetailer() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const company = useAppSelector((state) => state.app.company);
  const isLoading = useAppSelector((state) => state.app.isLoading);
  const supplier = useAppSelector((state) => state.app.supplier);
  const { personType } = useCompanyStatus();
  const { isAdmin } = useUserRole();

  const [isTermsOfServiceOpen, setIsTermsOfServiceOpen] = useState(false);
  const [isPrivacyPolicyOpen, setIsPrivacyPolicyOpen] = useState(false);
  const [hasReadTerms, setHasReadTerms] = useState(false);
  const [hasReadPrivacy, setHasReadPrivacy] = useState(false);

  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
  } = useForm<OnboardingForm>({
    defaultValues: {
      name: company?.name ?? '',
      fantasy: company?.fantasy ?? '',
      mainContactName: company?.mainContactName ?? '',
      mainContactDocNumber:
        personType === 'legal'
          ? company?.mainContactDocNumber ?? ''
          : company?.docNumber ?? '',
      mainContactPhone: company?.mainContactPhone ?? '',
      mainContactSelfieWithDoc: '',
      mainContactDocPicture: '',
      mainContactDocPictureBack: '',
      companyConstitutionDoc: '',
      optinAccept: false,
    },
  });

  const onSubmitClick = (data: OnboardingForm) => {
    const mainContactSelfieWithDocFile =
      data.mainContactSelfieWithDoc as unknown as File;
    const mainContactDocPictureFile =
      data.mainContactDocPicture as unknown as File;
    const mainContactDocPictureBackFile =
      data.mainContactDocPictureBack as unknown as File;
    const companyConstitutionDocFile =
      data.companyConstitutionDoc as unknown as File;

    dispatch(
      onboardingActions.completeRetailerOnboarding({
        data,
        mainContactSelfieWithDocFile,
        mainContactDocPictureFile,
        mainContactDocPictureBackFile,
        companyConstitutionDocFile,
        navigate,
      })
    );
  };

  const maxFileMB = 8;
  const acceptedFileTypes: MimeType[] = [
    'application/pdf',
    'application/zip',
    'image/jpeg',
    'image/png',
  ];
  const canCompleteOnboarding = !isAdmin;

  return (
    <>
      <Stack direction="column" overflow="auto" gap={2}>
        {!canCompleteOnboarding && (
          <Box marginBottom={2}>
            <Alert severity="warning" variant="outlined">
              Atenção! O usuário logado não pode completar o cadastro. Entre com
              o e-mail convidado.
            </Alert>
          </Box>
        )}
        <PageHeader title="Complete o cadastro" />

        <Grid2
          container
          sx={{ maxWidth: '1024px', marginX: 'auto' }}
          spacing={2}
        >
          <Grid2 xs={12} md={6}>
            <TextField
              fullWidth
              id="docNumber"
              name="docNumber"
              label={personType === 'legal' ? 'CNPJ' : 'CPF'}
              type="text"
              value={format(company?.docNumber, 'docNumber')}
              disabled
            />
          </Grid2>
          <Grid2 xs={12} md={6}>
            <TextField
              fullWidth
              id="name"
              label={personType === 'legal' ? 'Razão Social' : 'Nome'}
              type="text"
              {...register('name', {
                required: 'O nome é obrigatório',
              })}
              error={!!errors?.name}
              helperText={errors?.name?.message}
              disabled
            />
          </Grid2>

          <Grid2 xs={12} md={6}>
            <TextField
              fullWidth
              id="fantasy"
              label="Nome Fantasia"
              type="text"
              placeholder={company?.name ?? ''}
              {...register('fantasy', {
                required: 'O nome é obrigatório',
              })}
              error={!!errors?.fantasy}
              helperText={errors?.fantasy?.message}
              disabled
            />
          </Grid2>
          <Grid2
            xs={12}
            md={6}
            style={{
              display: personType === 'legal' ? 'block' : 'none',
            }}
          >
            <TextField
              fullWidth
              id="mainContactName"
              label="Nome do Responsável"
              type="text"
              {...register('mainContactName', {
                required: 'O nome é obrigatório',
              })}
              error={!!errors?.mainContactName}
              helperText={errors?.mainContactName?.message}
              disabled
            />
          </Grid2>
          <Grid2 xs={12} md={6}>
            <PhoneField
              fullWidth
              id="mainContactPhone"
              type="text"
              placeholder="(00) 00000-0000"
              label="Telefone"
              name="mainContactPhone"
              control={control}
            />
          </Grid2>
          <Grid2
            xs={12}
            md={6}
            style={{
              display: personType === 'legal' ? 'block' : 'none',
            }}
          >
            <CPFField
              fullWidth
              id="mainContactDocNumber"
              type="text"
              placeholder="000.000.000-00"
              label="CPF do responsável"
              name="mainContactDocNumber"
              control={control}
            />
          </Grid2>
          <Grid2 xs={12}>
            <Typography>
              Envie uma selfie segurando o documento (RG ou CNH) em frente ao
              rosto. Requisitos:
            </Typography>
          </Grid2>
          <Grid2 xs={12} md={6}>
            <List dense>
              <ListItem>
                <Typography variant="body2">
                  - Utilize um fundo neutro
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body2">
                  - O documento deve estar legível
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body2">
                  - Não utilize acessórios no rosto
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body2">
                  - Retire o documento do plástico
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body2">
                  - Formatos aceitos: PDF/PNG/JPG com tamanho máximo de{' '}
                  {maxFileMB}MB
                </Typography>
              </ListItem>
            </List>
          </Grid2>
          <Grid2 xs={12} md={6}>
            <Box display="flex" alignItems="center" flexDirection="column">
              <Box
                component="img"
                sx={{
                  maxWidth: 200,
                  borderColor: 'grey.500',
                  borderWidth: 1,
                  borderStyle: 'solid',
                  borderRadius: 2,
                }}
                alt="Selfie segurando o documento"
                src="id-selfie.jpg"
              />
              <Typography variant="caption">Exemplo:</Typography>
            </Box>
          </Grid2>
          <Grid2 xs={12}>
            <FileUploadWithControl
              fullWidth
              name="mainContactSelfieWithDoc"
              control={control}
              rules={{
                required: 'O arquivo é obrigatório',
                validate: (v) => {
                  const file = v as unknown as File;
                  if (!file) return 'O arquivo é obrigatório';
                  if (!validateFileSize(file, maxFileMB * 1024 * 1024))
                    return `O tamanho máximo deve ser de ${maxFileMB} MB`;

                  if (!validateFileType(file, acceptedFileTypes))
                    return 'Os tipos aceitos são PNG, JPG e PDF';
                  return true;
                },
              }}
              label={`Selfie segurando o documento (PDF/PNG/JPG - máx ${maxFileMB}MB)`}
            />
          </Grid2>
          <Grid2 xs={12}>
            <Typography>
              Envie uma foto do documento (o mesmo da selfie). Requisitos:
            </Typography>
          </Grid2>
          <Grid2 xs={12} md={6}>
            <List dense>
              <ListItem>
                <Typography variant="body2">
                  - Utilize um fundo neutro
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body2">
                  - O documento deve estar legível
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body2">
                  - Retire o documento do plástico
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body2">
                  - Use o primeiro campo abaixo para mandar uma foto única
                  frente/verso
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body2">
                  - Se for tirar 2 fotos (uma frente e outra verso), utilize os
                  2 campos abaixo
                </Typography>
              </ListItem>
              <ListItem>
                <Typography variant="body2">
                  - Formatos aceitos: PDF/PNG/JPG com tamanho máximo de{' '}
                  {maxFileMB}MB
                </Typography>
              </ListItem>
            </List>
          </Grid2>
          <Grid2 xs={12} md={6}>
            <Box display="flex" alignItems="center" flexDirection="column">
              <Box
                component="img"
                sx={{
                  maxWidth: 200,
                  borderColor: 'grey.500',
                  borderWidth: 1,
                  borderStyle: 'solid',
                  borderRadius: 2,
                }}
                alt="exemplo rg ou cnh"
                src="cnh.jpg"
              />
              <Typography variant="caption">Exemplo:</Typography>
            </Box>
          </Grid2>
          <Grid2 xs={12} md={6}>
            <FileUploadWithControl
              fullWidth
              name="mainContactDocPicture"
              control={control}
              rules={{
                required: 'O arquivo é obrigatório',
                validate: (v) => {
                  const file = v as unknown as File;
                  if (!file) return 'O arquivo é obrigatório';
                  if (!validateFileSize(file, maxFileMB * 1024 * 1024))
                    return `O tamanho máximo deve ser de ${maxFileMB} MB`;

                  if (!validateFileType(file, acceptedFileTypes))
                    return 'Os tipos aceitos são PNG, JPG e PDF';
                  return true;
                },
              }}
              label={`RG ou CNH Frente ou Frente/Verso (PNG ou JPG - máx ${maxFileMB}MB)`}
            />
          </Grid2>
          <Grid2 xs={12} md={6}>
            <FileUploadWithControl
              fullWidth
              name="mainContactDocPictureBack"
              control={control}
              rules={{
                validate: (v) => {
                  const file = v as unknown as File;
                  if (!file) return true;
                  if (!validateFileSize(file, maxFileMB * 1024 * 1024))
                    return `O tamanho máximo deve ser de ${maxFileMB} MB`;

                  if (!validateFileType(file, acceptedFileTypes))
                    return 'Os tipos aceitos são PNG, JPG e PDF';
                  return true;
                },
              }}
              label={`RG ou CNH Verso (PNG ou JPG - máx ${maxFileMB}MB)`}
            />
          </Grid2>
          <Grid2
            xs={12}
            style={{
              display: personType === 'legal' ? 'block' : 'none',
            }}
          >
            <FileUploadWithControl
              fullWidth
              name="companyConstitutionDoc"
              control={control}
              rules={{
                required:
                  personType === 'legal' ? 'O arquivo é obrigatório' : false,
                validate: (v) => {
                  const file = v as unknown as File;
                  if (!file)
                    return personType === 'legal'
                      ? 'O arquivo é obrigatório'
                      : true;
                  if (!validateFileSize(file, maxFileMB * 1024 * 1024))
                    return `O tamanho máximo deve ser de ${maxFileMB} MB`;

                  if (!validateFileType(file, acceptedFileTypes))
                    return 'Os tipos aceitos são PNG, JPG e PDF';
                  return true;
                },
              }}
              label="Contrato social"
            />
          </Grid2>
          <Grid2 xs={12}>
            <Box
              display="flex"
              justifyContent="flex-start"
              flexDirection="column"
              gap={2}
            >
              <Typography variant="body2">
                Política de Privacidade e Termo de Uso
              </Typography>
              <Typography variant="caption" color="text.secondary">
                Ao contratar a Pague Recebível LTDA., inscrita no CNPJ sob o nº
                51.012.786/0001-06 (“PAGUE RECEBIVEL”), você declara que possui
                capacidade legal e poderes de representação e concorda de forma
                livre, expressa, informada e integral com a Política de
                Privacidade e os Termos de Uso da Pague Recebível. Ainda, você
                declara que atuará sempre em benefício da pessoa jurídica que
                representa. A formalização poderá ser realizada por meio de
                WhatsApp, SMS, assinatura, pela realização de qualquer Operação
                sobre Recebíveis ou por qualquer outra forma juridicamente
                válida e será considerada válida para a matriz e filiais
                atreladas ao mesmo CNPJ.
              </Typography>
              <Typography variant="body2">
                Clique nos botões para visualizar os termos de uso e política de
                privacidade
              </Typography>
              <Box display="flex" flexDirection="row" gap={2}>
                <Button
                  variant="outlined"
                  color={hasReadPrivacy ? 'success' : 'primary'}
                  endIcon={
                    hasReadPrivacy ? <CheckIcon /> : <ArrowOutwardIcon />
                  }
                  onClick={() => setIsPrivacyPolicyOpen(true)}
                >
                  Política de Privacidade
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => setIsTermsOfServiceOpen(true)}
                  color={hasReadTerms ? 'success' : 'primary'}
                  endIcon={hasReadTerms ? <CheckIcon /> : <ArrowOutwardIcon />}
                >
                  Termos de Uso
                </Button>
              </Box>

              <CheckBoxField
                control={control}
                id="optinAccept"
                name="optinAccept"
                label="Declaro que li e aceito os termos de uso e política de privacidade"
                customValidate={(v) => {
                  if (v && hasReadTerms && hasReadPrivacy) return true;
                  if (!hasReadPrivacy)
                    return 'Você deve ler a política de privacidade. Clique no botão POLÍTICA DE PRIVACIDADE acima para visualizar a política de privacidade';
                  if (!hasReadTerms)
                    return 'Você deve ler os termos de uso. Clique no botão TERMOS DE USO acima para visualizar os termos de uso';
                  if (!v)
                    return 'Você deve aceitar a declaração para poder continuar';
                }}
              />
            </Box>
          </Grid2>
          {Object.keys(errors).length > 0 && (
            <Grid2 xs={12}>
              <Alert variant="outlined" severity="error">
                Corrija os erros para prosseguir.
              </Alert>
            </Grid2>
          )}
          <Grid2 xs={12}>
            <Box pb={2} display="flex" justifyContent="center">
              <LoadingButton
                disabled={!canCompleteOnboarding}
                onClick={handleSubmit(onSubmitClick)}
                type="submit"
                loading={isLoading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
              >
                Salvar
              </LoadingButton>
            </Box>
          </Grid2>
        </Grid2>
      </Stack>
      <ResponsiveDialog open={isTermsOfServiceOpen} maxWidth="xl" fullWidth>
        <TermsOfServiceRetailer
          supplierGender={supplier?.gender ?? ''}
          supplierName={supplier?.name ?? ''}
          onFinishReading={() => {
            setIsTermsOfServiceOpen(false);
            setHasReadTerms(true);
          }}
        />
      </ResponsiveDialog>
      <ResponsiveDialog
        open={isPrivacyPolicyOpen}
        maxWidth="xl"
        fullWidth
        onClose={() => setIsPrivacyPolicyOpen}
      >
        <PrivacyPolicyRetailer
          onFinishReading={() => {
            setIsPrivacyPolicyOpen(false);
            setHasReadPrivacy(true);
          }}
        />
      </ResponsiveDialog>
    </>
  );
}

export default OnboardingRetailer;
